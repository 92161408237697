import {LanguageText, NewWaterSourceWizardStep, ValueType} from "./campaign-config.dto";

export const defaultNewWaterSourceWizard: NewWaterSourceWizardStep[] = [
  new NewWaterSourceWizardStep(
    "images",
    true,
    ValueType.IMAGE
  ),
  new NewWaterSourceWizardStep(
    "type",
    false,
    ValueType.STRING,
    [
      new LanguageText(
        "en",
        "Select the water body type",
      ),
      new LanguageText(
        "pt",
        "Selecionar tipo de corpo de água",
      ),
      new LanguageText(
        "fr",
        "Sélectionnez le type de plan d'eau",
      )
    ],
    [
      new LanguageText(
        "en",
        "Swamp",
        "swamp"
      ),
      new LanguageText(
        "en",
        "Pond",
        "pond"
      ),
      new LanguageText(
        "en",
        "Puddle",
        "puddle"
      ),
      new LanguageText(
        "en",
        "Manmade",
        "manmade"
      ),
      new LanguageText(
        "en",
        "Agricultural",
        "agriculture"
      ),
      new LanguageText(
        "en",
        "Construcion",
        "construction"
      ),
      new LanguageText(
        "en",
        "Animal tracks",
        "footprint"
      ),
      new LanguageText(
        "en",
        "Tire tracks",
        "tracks"
      ),
      new LanguageText(
        "en",
        "Ditch",
        "ditch"
      ),
      new LanguageText(
        "en",
        "Pool",
        "pool"
      ),
      new LanguageText(
        "en",
        "Tire",
        "tire"
      ),
      new LanguageText(
        "en",
        "Other",
        "other"
      ),
      new LanguageText(
        "pt",
        "Pântano",
        "swamp"
      ),
      new LanguageText(
        "pt",
        "Lago",
        "pond"
      ),
      new LanguageText(
        "pt",
        "Poça",
        "puddle"
      ),
      new LanguageText(
        "pt",
        "Artificial",
        "manmade"
      ),
      new LanguageText(
        "pt",
        "Agrícola",
        "agriculture"
      ),
      new LanguageText(
        "pt",
        "Construção",
        "construction"
      ),
      new LanguageText(
        "pt",
        "Pegadas",
        "footprint"
      ),
      new LanguageText(
        "pt",
        "Marcas de pneus",
        "tracks"
      ),
      new LanguageText(
        "pt",
        "Vala",
        "ditch"
      ),
      new LanguageText(
        "pt",
        "Piscina",
        "pool"
      ),
      new LanguageText(
        "pt",
        "Pneu",
        "tire"
      ),
      new LanguageText(
        "pt",
        "Outros",
        "other"
      ),
      new LanguageText(
        "fr",
        "Marais",
        "swamp"
      ),
      new LanguageText(
        "fr",
        "Étang",
        "pond"
      ),
      new LanguageText(
        "fr",
        "Flaque d'eau",
        "puddle"
      ),
      new LanguageText(
        "fr",
        "Artificiel",
        "manmade"
      ),
      new LanguageText(
        "fr",
        "Agriculture",
        "agriculture"
      ),
      new LanguageText(
        "fr",
        "Construcion",
        "construction"
      ),
      new LanguageText(
        "fr",
        "Traces d'animaux",
        "footprint"
      ),
      new LanguageText(
        "fr",
        "Traces de pneus",
        "tracks"
      ),
      new LanguageText(
        "fr",
        "Fossé",
        "ditch"
      ),
      new LanguageText(
        "fr",
        "Piscine",
        "pool"
      ),
      new LanguageText(
        "fr",
        "Pneu",
        "tire"
      ),
      new LanguageText(
        "fr",
        "Autre",
        "other"
      )
    ]
  ),
  new NewWaterSourceWizardStep(
    "size",
    false,
    ValueType.STRING,
    [
      new LanguageText(
        "en",
        "Size"
      ),
      new LanguageText(
        "pt",
        "Tamanho"
      ),
      new LanguageText(
        "fr",
        "Taille"
      )
    ],
    [
      new LanguageText(
        "en",
        "Very small\n(<1 m)",
        "tiny"
      ),
      new LanguageText(
        "en",
        "Small\n(1-3 m)",
        "small"
      ),
      new LanguageText(
        "en",
        "Medium\n(3-10 m)",
        "medium"
      ),
      new LanguageText(
        "en",
        "Large\n(>10 m)",
        "large"
      ),
      new LanguageText(
        "pt",
        "Muito pequeno\n(<1 m)",
        "tiny"
      ),
      new LanguageText(
        "pt",
        "Pequeno\n(1–3 m)",
        "small"
      ),
      new LanguageText(
        "pt",
        "Médio\n(3–10 m)",
        "medium"
      ),
      new LanguageText(
        "pt",
        "Grande\n(>10 m)",
        "large"
      ),
      new LanguageText(
        "fr",
        "Très petite\n(<1 m)",
        "tiny"
      ),
      new LanguageText(
        "fr",
        "Petite\n(1-3 m)",
        "small"
      ),
      new LanguageText(
        "fr",
        "Moyenne\n(3–10 m)",
        "medium"
      ),
      new LanguageText(
        "fr",
        "Grande\n(>10 m)",
        "large"
      )
    ]
  ),
  new NewWaterSourceWizardStep(
    "depth",
    false,
    ValueType.INT,
    [
      new LanguageText(
        "en",
        "Depth"
      ),
      new LanguageText(
        "pt",
        "Profundidade"
      ),
      new LanguageText(
        "fr",
        "Profondeur"
      )
    ],
    [
      new LanguageText(
        "en",
        "< 10 cm",
        5
      ),
      new LanguageText(
        "en",
        "10-50 cm",
        25
      ),
      new LanguageText(
        "en",
        ">50 cm",
        70
      ),
      new LanguageText(
        "pt",
        "<10 cm",
        5
      ),
      new LanguageText(
        "pt",
        "10-50 cm",
        25
      ),
      new LanguageText(
        "pt",
        ">50 cm",
        70
      ),
      new LanguageText(
        "fr",
        "< 10 cm",
        5
      ),
      new LanguageText(
        "fr",
        "10-50 cm",
        25
      ),
      new LanguageText(
        "fr",
        ">50 cm",
        70
      )
    ]
  ),
  new NewWaterSourceWizardStep(
    "isCustomTemporary",
    false,
    ValueType.STRING,
    [
      new LanguageText(
        "en",
        "Temporary site"
      ),
      new LanguageText(
        "pt",
        "Temporário"
      ),
      new LanguageText(
        "fr",
        "Site temporaire"
      )
    ],
    [
      new LanguageText(
        "en",
        "Temporary",
        "temporary"
      ),
      new LanguageText(
        "en",
        "Semi-permanent",
        "semi-permanent"
      ),
      new LanguageText(
        "en",
        "Permanent",
        "permanent"
      ),
      new LanguageText(
        "pt",
        "Temporário",
        "temporary"
      ),
      new LanguageText(
        "pt",
        "Semi-permanente",
        "semi-permanent"
      ),
      new LanguageText(
        "pt",
        "Permanente",
        "permanent"
      ),
      new LanguageText(
        "fr",
        "Temporaire",
        "temporary"
      ),
      new LanguageText(
        "fr",
        "Semi-permanent",
        "semi-permanent"
      ),
      new LanguageText(
        "fr",
        "Permanent",
        "permanent"
      ),
    ]
  ),
  new NewWaterSourceWizardStep(
    "isCustomPolluted",
    false,
    ValueType.STRING,
    [
      new LanguageText(
        "en",
        "Polluted water"
      ),
      new LanguageText(
        "pt",
        "Água poluida?"
      ),
      new LanguageText(
        "fr",
        "Eau polluée"
      )
    ],
    [
      new LanguageText(
        "en",
        "Clean",
        "clean"
      ),
      new LanguageText(
        "en",
        "Polluted",
        "polluted"
      ),
      new LanguageText(
        "pt",
        "Limpa",
        "clean"
      ),
      new LanguageText(
        "pt",
        "Poluída",
        "polluted"
      ),
      new LanguageText(
        "fr",
        "Faire le ménage",
        "clean"
      ),
      new LanguageText(
        "fr",
        "Pollué",
        "polluted"
      )
    ]
  ),
  new NewWaterSourceWizardStep(
    "isVegetation",
    false,
    ValueType.BOOLEAN,
    [
      new LanguageText(
        "en",
        "Vegetation cover"
      ),
      new LanguageText(
        "pt",
        "Vegetação no topo"
      ),
      new LanguageText(
        "fr",
        "Couverture végétale"
      )
    ],
    [
      new LanguageText(
        "en",
        "Vegetation cover",
        "true"
      ),
      new LanguageText(
        "en",
        "No vegetation",
        "false"
      ),
      new LanguageText(
        "pt",
        "Vegetação no topo",
        "true"
      ),
      new LanguageText(
        "pt",
        "Sem vegetação",
        "false"
      ),
      new LanguageText(
        "fr",
        "Couverture végétale",
        "true"
      ),
      new LanguageText(
        "fr",
        "Pas de végétation",
        "false"
      )
    ]
  )
]
