import {Injectable} from '@angular/core';
import {Observable, retry, shareReplay, Subject, takeUntil, tap} from "rxjs";
import { HttpClient } from "@angular/common/http";
import {Campaign, CampaignAdapter} from "../core/CampaignAdapter";
import {map} from "rxjs/operators";
import {BehaviorSubject} from "rxjs/internal/BehaviorSubject";
import {environment} from "../../environments/environment";
import {CampaignStats, CampaignStatsAdapter} from "../core/CampaignStatsAdapter";
import {CookieService} from "ngx-cookie-service";
import {VillageService} from "../village/village.service";
import {UserService} from "../user/user.service";

@Injectable({
  providedIn: 'root'
})
export class CampaignService {
  constructor(private http: HttpClient,
              private villageService: VillageService,
              private userService: UserService,
              private campaignStatsAdapter: CampaignStatsAdapter,
              private campaignAdapter: CampaignAdapter,
              private cookieService: CookieService) {
    this.getCampaigns();

    if (cookieService.check("selectedCampaign")) {
      const campaign = JSON.parse(cookieService.get("selectedCampaign")) as Campaign;
      this.selectCampaign(campaign);
    }

    this.selectedCampaign$.subscribe((campaign: Campaign | undefined) => {
      if (campaign) {
        this.villageService.getVillages();
        this.userService.getUsers();
      }
    });
  }

  private _campaigns$ = new BehaviorSubject<Campaign[]>([]);
  private destroy$ = new Subject<void>();

  cancelRequest() {
    this.destroy$.next();
  }

  get campaigns$(): Observable<Campaign[]> {
    return this._campaigns$.asObservable();
  }

  private _selectedCampaign$ = new BehaviorSubject<Campaign | undefined>(undefined);

  get selectedCampaign$(): Observable<Campaign | undefined> {
    return this._selectedCampaign$.asObservable();
  }

  get selectedCampaign(): Campaign | undefined {
    return this._selectedCampaign$.getValue();
  }

  private _campaignStats$ = new BehaviorSubject<CampaignStats | undefined>(undefined);

  get campaignStats$(): Observable<CampaignStats | undefined> {
    return this._campaignStats$.asObservable();
  }

  private getCampaigns() {
    const getCampaignApi = `${environment.BASE_URL}/campaigns`;

    this.http.get(getCampaignApi).pipe(
      map((data: any) => data.map((item: any) => this.campaignAdapter.adapt(item))),
      map((campaigns: Campaign[]) => campaigns.sort((a: Campaign, b: Campaign) => a.name.localeCompare(b.name))),
      shareReplay(1)
    ).subscribe((campaigns: Campaign[]) => {
      this._campaigns$.next(campaigns);
    });
  }

  getCampaign(campaignId: number, shouldSelectCampaign: boolean = true): Observable<Campaign> {
    const getCampaignApi = `${environment.BASE_URL}/campaigns/${campaignId}?include=settings`;

    return this.http.get(getCampaignApi).pipe(
      takeUntil(this.destroy$),
      map((data: any) => this.campaignAdapter.adapt(data)),
      shareReplay(1),
      tap((campaign: Campaign) => {
        if (shouldSelectCampaign) {
          this._selectedCampaign$.next(campaign);
        }
      })
    );
  }

  getCampaignStats(filter: {
    limitDays?: number,
    start?: number,
    end?: number
  } = {limitDays: 90}): void {
    let getCampaignStatsApi = `${environment.BASE_URL}/campaigns/stats?`;

    if (filter.limitDays) {
      getCampaignStatsApi += `limitDays=${filter.limitDays}`;
    } else if (filter.start || filter.end) {
      if (filter.start) {
        getCampaignStatsApi += `start=${filter.start}`;
      }
      if (filter.end) {
        getCampaignStatsApi += `&end=${filter.end}`;
      }
    }

    this._campaignStats$.next(undefined);

    this.http.get(getCampaignStatsApi).pipe(
      takeUntil(this.destroy$),
      retry(3),
      map((data: any) => this.campaignStatsAdapter.adapt(data)),
      tap((campaignStats: CampaignStats) => {
        this._campaignStats$.next(campaignStats);
      })
    ).subscribe();
  }

  create(data: any): Observable<Campaign> {
    const createUrl = `${environment.BASE_URL}/campaigns`;

    return this.http.post(createUrl, data).pipe(
      takeUntil(this.destroy$),
      map((item: any) => this.campaignAdapter.adapt(item)),
      tap((campaign: Campaign) => {
        let campaigns = this._campaigns$.getValue();
        campaigns.push(campaign);
        campaigns = campaigns.sort((a: Campaign, b: Campaign) => a.name.localeCompare(b.name));
        this._campaigns$.next(campaigns);
      })
    );
  }

  update(campaignId: number, data: any): Observable<Campaign> {
    const updateUrl = `${environment.BASE_URL}/campaigns/${campaignId}`;

    return this.http.put(updateUrl, data).pipe(
      takeUntil(this.destroy$),
      map((item: any) => this.campaignAdapter.adapt(item)),
      tap((campaign: Campaign) => {
        const campaigns = this._campaigns$.getValue();
        const index = campaigns.findIndex((campaign) => campaign.id === campaignId);
        campaigns[index] = campaign;
        this._campaigns$.next(campaigns);
        const selectedCampaignIndex = campaigns.findIndex((campaign: Campaign) => campaign.id === this.selectedCampaign?.id);
        this._selectedCampaign$.next(campaigns[selectedCampaignIndex]);
      })
    );
  }

  selectCampaign(campaign: Campaign | undefined) {
    if (!campaign) {
      this._selectedCampaign$.next(undefined);
      this.cookieService.delete('selectedCampaign');
    } else {
      this.cancelRequest();
      this.villageService.cancelRequest();
      this.cookieService.set('selectedCampaign', JSON.stringify(campaign), undefined, '/');

      this.getCampaign(campaign.id).subscribe();
    }
  }

  deleteCampaign(campaignId: number) {
    const deleteUrl = `${environment.BASE_URL}/campaigns/${campaignId}`;

    return this.http.delete(deleteUrl).pipe(
      takeUntil(this.destroy$),
      tap(() => {
        let campaigns = this._campaigns$.getValue();
        campaigns = campaigns.filter((campaign) => campaign.id !== campaignId);
        this._campaigns$.next(campaigns);
      })
    );
  }
}
