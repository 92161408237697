import {Adapter} from "./Adapter";
import {Injectable} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import * as fp from 'lodash/fp';

const {filter, flow, head} = fp;

export interface Action {
  readonly action: string;
  readonly reportedOn: number;
  readonly createdBy: string;
  readonly assignmentId?: number;
}

export class TreatAction implements Action {
  readonly action: string = 'treat';
  readonly reportedOn: number;
  readonly createdBy: string;
  readonly actionType?: string;
  readonly isLarvaePresent?: boolean;
  readonly isAnopheles12Present?: boolean;
  readonly isAnopheles34Present?: boolean;
  readonly isCulex12Present?: boolean;
  readonly isCulex34Present?: boolean;
  readonly isPupaePresent?: boolean;
  readonly assignmentId?: number;

  constructor(reportedOn: number,
              createdBy: string,
              actionType?: string,
              isLarvaePresent?: boolean,
              isAnopheles12Present?: boolean,
              isAnopheles34Present?: boolean,
              isCulex12Present?: boolean,
              isCulex34Present?: boolean,
              isPupaePresent?: boolean,
              assignmentId?: number
  ) {
    this.reportedOn = reportedOn;
    this.createdBy = createdBy;
    this.actionType = actionType;
    this.isLarvaePresent = isLarvaePresent;
    this.isAnopheles12Present = isAnopheles12Present;
    this.isAnopheles34Present = isAnopheles34Present;
    this.isCulex12Present = isCulex12Present;
    this.isCulex34Present = isCulex34Present;
    this.isPupaePresent = isPupaePresent;
    this.assignmentId = assignmentId;
  }
}

export class SampleAction implements Action {
  readonly action: string = 'sample';
  readonly createdBy: string;
  readonly reportedOn: number;
  readonly anopheles: number;
  readonly culex: number;
  readonly pupae: number;
  readonly assignmentId?: number;

  constructor(
    createdBy: string,
    reportedOn: number,
    anopheles: number,
    culex: number,
    pupae: number,
    assignmentId?: number) {
    this.createdBy = createdBy;
    this.reportedOn = reportedOn;
    this.anopheles = anopheles;
    this.culex = culex;
    this.pupae = pupae;
    this.assignmentId = assignmentId;
  }
}

export class IssueAction implements Action {
  readonly action: string = 'issue';
  readonly reportedOn: number;
  readonly createdBy: string;
  readonly type: string;
  readonly assignmentId?: number;

  constructor(reportedOn: number,
              createdBy: string,
              type: string,
              assignmentId?: number) {
    this.reportedOn = reportedOn;
    this.createdBy = createdBy;
    this.type = type;
    this.assignmentId = assignmentId;
  }
}

export class WaterSource {
  readonly createdBy: string;
  readonly id: string;
  readonly isIssued: boolean;
  readonly isSampled: boolean;
  readonly isTreated: boolean;
  readonly location: {
    readonly latitude: number;
    readonly longitude: number;
  };
  readonly modifiedOn: number;
  readonly reportedOn: number;
  readonly sourceBy: string;
  readonly status: string;
  readonly images: string[];
  readonly type: string;
  readonly properties: any;
  actions: Action[];
  readonly villageId: number;
  readonly targeting: boolean;
  readonly assignmentId?: number;

  constructor(
    createdBy: string,
    id: string,
    isIssued: boolean,
    isSampled: boolean,
    isTreated: boolean,
    location: {
      latitude: number;
      longitude: number;
    },
    modifiedOn: number,
    reportedOn: number,
    sourceBy: string,
    status: string,
    images: string[],
    type: string,
    properties: any,
    actions: Action[] = [],
    villageId: number,
    targeting: boolean,
    assignmentId?: number,) {
    this.createdBy = createdBy;
    this.id = id;
    this.isIssued = isIssued;
    this.isSampled = isSampled;
    this.isTreated = isTreated;
    this.location = location;
    this.modifiedOn = modifiedOn;
    this.reportedOn = reportedOn;
    this.sourceBy = sourceBy;
    this.status = status;
    this.images = images;
    this.type = type;
    this.properties = properties;
    this.actions = actions;
    this.villageId = villageId;
    this.targeting = targeting;
    this.assignmentId = assignmentId;
  }
}

@Injectable({
  providedIn: 'root'
})
export class WaterSourceAdapter implements Adapter<WaterSource> {
  constructor(private translationService: TranslateService) {
  }

  adapt(item: any): WaterSource {
    let actions: Action[] | undefined;
    let status: string;

    if (item.treats && item.samples && item.issues) {
      const treats = item.treats.map((treat: any) => this.adaptTreat(treat));
      const samples = item.samples.map((sample: any) => this.adaptSample(sample));
      const issues = item.issues.map((issue: any) => this.adaptIssue(issue));
      actions = [...treats, ...samples, ...issues].sort((a, b) => b.reportedOn - a.reportedOn);
    }

    const lastSample = flow(filter((item: Action) => item.action == "sample"), head)(actions);
    // @ts-ignore
    const isPositive = lastSample && (lastSample.anopheles > 0 || lastSample.culex > 0 || lastSample.pupae > 0);
    const flag = item.properties['flag'] as WaterSourceFlag;

    if (item.isTreated && item.isSampled && item.isIssued) {
      if (isPositive) {
        switch (flag) {
          case WaterSourceFlag.ORANGE: {
            status = 'treated-sampled-positive-issued-orange';
            break;
          }
          case WaterSourceFlag.YELLOW: {
            status = 'treated-sampled-positive-issued-yellow';
            break;
          }
          case WaterSourceFlag.PURPLE: {
            status = 'treated-sampled-positive-issued-purple';
            break;
          }
          default: {
            status = 'treated-sampled-positive-issued';
          }
        }
      } else {
        switch (flag) {
          case WaterSourceFlag.ORANGE: {
            status = 'treated-sampled-issued-orange';
            break;
          }
          case WaterSourceFlag.YELLOW: {
            status = 'treated-sampled-issued-yellow';
            break;
          }
          case WaterSourceFlag.PURPLE: {
            status = 'treated-sampled-issued-purple';
            break;
          }
          default: {
            status = 'treated-sampled-issued';
          }
        }
      }
    } else if (item.isTreated && item.isSampled) {
      if (isPositive) {
        switch (flag) {
          case WaterSourceFlag.ORANGE: {
            status = 'treated-sampled-positive-orange';
            break;
          }
          case WaterSourceFlag.YELLOW: {
            status = 'treated-sampled-positive-yellow';
            break;
          }
          case WaterSourceFlag.PURPLE: {
            status = 'treated-sampled-positive-purple';
            break;
          }
          default: {
            status = 'treated-sampled-positive';
          }
        }
      } else {
        switch (flag) {
          case WaterSourceFlag.ORANGE: {
            status = 'treated-sampled-orange';
            break;
          }
          case WaterSourceFlag.YELLOW: {
            status = 'treated-sampled-yellow';
            break;
          }
          case WaterSourceFlag.PURPLE: {
            status = 'treated-sampled-purple';
            break;
          }
          default: {
            status = 'treated-sampled';
          }
        }
      }
    } else if (item.isTreated && item.isIssued) {
      switch (flag) {
        case WaterSourceFlag.ORANGE: {
          status = 'treated-issued-orange';
          break;
        }
        case WaterSourceFlag.YELLOW: {
          status = 'treated-issued-yellow';
          break;
        }
        case WaterSourceFlag.PURPLE: {
          status = 'treated-issued-purple';
          break;
        }
        default: {
          status = 'treated-issued';
        }
      }
    } else if (item.isSampled && item.isIssued) {
      if (isPositive) {
        switch (flag) {
          case WaterSourceFlag.ORANGE: {
            status = 'sampled-positive-issued-orange';
            break;
          }
          case WaterSourceFlag.YELLOW: {
            status = 'sampled-positive-issued-yellow';
            break;
          }
          case WaterSourceFlag.PURPLE: {
            status = 'sampled-positive-issued-purple';
            break;
          }
          default: {
            status = 'sampled-positive-issued';
          }
        }
      } else {
        switch (flag) {
          case WaterSourceFlag.ORANGE: {
            status = 'sampled-issued-orange';
            break;
          }
          case WaterSourceFlag.YELLOW: {
            status = 'sampled-issued-yellow';
            break;
          }
          case WaterSourceFlag.PURPLE: {
            status = 'sampled-issued-purple';
            break;
          }
          default: {
            status = 'sampled-issued';
          }
        }
      }
    } else if (item.isTreated) {
      switch (flag) {
        case WaterSourceFlag.ORANGE: {
          status = 'treated-orange';
          break;
        }
        case WaterSourceFlag.YELLOW: {
          status = 'treated-yellow';
          break;
        }
        case WaterSourceFlag.PURPLE: {
          status = 'treated-purple';
          break;
        }
        default: {
          status = 'treated';
        }
      }
    } else if (item.isSampled) {
      if (isPositive) {
        switch (flag) {
          case WaterSourceFlag.ORANGE: {
            status = 'sampled-positive-orange';
            break;
          }
          case WaterSourceFlag.YELLOW: {
            status = 'sampled-positive-yellow';
            break;
          }
          case WaterSourceFlag.PURPLE: {
            status = 'sampled-positive-purple';
            break;
          }
          default: {
            status = 'sampled-positive';
          }
        }
      } else {
        switch (flag) {
          case WaterSourceFlag.ORANGE: {
            status = 'sampled-orange';
            break;
          }
          case WaterSourceFlag.YELLOW: {
            status = 'sampled-yellow';
            break;
          }
          case WaterSourceFlag.PURPLE: {
            status = 'sampled-purple';
            break;
          }
          default: {
            status = 'sampled';
          }
        }
      }
    } else if (item.isIssued) {
      switch (flag) {
        case WaterSourceFlag.ORANGE: {
          status = 'issued-orange';
          break;
        }
        case WaterSourceFlag.YELLOW: {
          status = 'issued-yellow';
          break;
        }
        case WaterSourceFlag.PURPLE: {
          status = 'issued-purple';
          break;
        }
        default: {
          status = 'issued';
        }
      }
    } else {
      switch (flag) {
        case WaterSourceFlag.ORANGE: {
          status = 'new-orange';
          break;
        }
        case WaterSourceFlag.YELLOW: {
          status = 'new-yellow';
          break;
        }
        case WaterSourceFlag.PURPLE: {
          status = 'new-purple';
          break;
        }
        default: {
          status = 'new';
        }
      }
    }

    return new WaterSource(
      item.createdBy,
      item.id,
      item.isIssued,
      item.isSampled,
      item.isTreated,
      item.location,
      item.modifiedOn,
      item.reportedOn,
      item.sourceBy,
      status,
      item.images,
      item.type,
      item.properties,
      actions,
      item.villageId,
      Boolean(item.properties?.targeting),
      item.assignmentId
    );
  }

  private adaptTreat(item: any): TreatAction {
    const actionType = this.translationService.instant(`treatAction.${item.action}`);

    return new TreatAction(
      item.reportedOn,
      item.createdBy,
      actionType,
      item.properties?.isLarvaePresent,
      item.properties?.isAnopheles12Present,
      item.properties?.isAnopheles34Present,
      item.properties?.isCulex12Present,
      item.properties?.isCulex34Present,
      item.properties?.isPupaePresent,
      item.assignmentId
    );
  }

  private adaptSample(item: any): SampleAction {
    return new SampleAction(
      item.createdBy,
      item.reportedOn,
      item.anopheles,
      item.culex,
      item.pupae,
      item.assignmentId
    );
  }

  private adaptIssue(item: any): IssueAction {
    return new IssueAction(
      item.reportedOn,
      item.createdBy,
      item.type,
      item.assignmentId
    );
  }
}

export enum WaterSourceFlag {
  ORANGE = 0,
  YELLOW = 1,
  PURPLE = 2
}
