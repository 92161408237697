import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MapService {
  private villageFocus$: BehaviorSubject<[number, number | undefined, number[] | undefined, boolean] | undefined> = new BehaviorSubject<[number, number | undefined, number[] | undefined, boolean] | undefined>(undefined);

  constructor() {
  }

  get villageFocus(): Observable<[number, number | undefined, number[] | undefined, boolean] | undefined> {
    return this.villageFocus$.asObservable();
  }

  focusOnVillage(villageId: number | undefined, taskId?: number | undefined, assignmentIds?: number[] | undefined, resetReview: boolean = false): void {
    if (villageId === undefined) {
      this.villageFocus$.next(undefined);
    } else {
      this.villageFocus$.next([villageId, taskId, assignmentIds, resetReview]);
    }
  }
}
