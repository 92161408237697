import {Injectable} from "@angular/core";
import {Adapter} from "./Adapter";

export enum TaskStatus {
  PENDING = 0,
  WIP = 1,
  COMPLETED = 2,
  CANCELLED = 3,
  QA = 4,
  REDO = 5,
  APPROVED = 6,
  AUTO_APPROVED = 7,
  CONTINUE = 8,
  UNASSIGNED = 9,
}

export enum WorkType {
  SAMPLE = 0,
  TREAT = 1,
  SURVEY_SAMPLE_TREAT = 2,
  QA_SURVEY = 3,
  QA_TREAT = 4,
  SAMPLE_TREAT = 5,
  SURVEY_TREAT = 6,
  SURVEY_SAMPLE = 7,
  SURVEY = 8,
}

export class Assignment {
  readonly id: number;
  readonly userId: number;
  readonly order: number;
  readonly status: TaskStatus;
  readonly modifiedOn: number;
  readonly assignedOn: number;

  constructor(
    id: number,
    userId: number,
    order: number,
    status: TaskStatus,
    modifiedOn: number,
    assignedOn: number,
  ) {
    this.id = id;
    this.userId = userId;
    this.order = order;
    this.status = status;
    this.modifiedOn = modifiedOn;
    this.assignedOn = assignedOn;
  }
}

export class SampleResult {
  culexL1_2PositiveCount: number;
  culexL1_2NegativeCount: number;
  culexL3_4PositiveCount: number;
  culexL3_4NegativeCount: number;
  culexPositiveCount: number;
  culexNegativeCount: number;
  anophelesL1_2PositiveCount: number;
  anophelesL1_2NegativeCount: number;
  anophelesL3_4PositiveCount: number;
  anophelesL3_4NegativeCount: number;
  anophelesPositiveCount: number;
  anophelesNegativeCount: number;
  pupaePositiveCount: number;
  pupaeNegativeCount: number;
  positiveCount: number;
  negativeCount: number;

  constructor(
    culexL1_2PositiveCount: number,
    culexL1_2NegativeCount: number,
    culexL3_4PositiveCount: number,
    culexL3_4NegativeCount: number,
    culexPositiveCount: number,
    culexNegativeCount: number,
    anophelesL1_2PositiveCount: number,
    anophelesL1_2NegativeCount: number,
    anophelesL3_4PositiveCount: number,
    anophelesL3_4NegativeCount: number,
    anophelesPositiveCount: number,
    anophelesNegativeCount: number,
    pupaePositiveCount: number,
    pupaeNegativeCount: number,
    positiveCount: number,
    negativeCount: number,
  ) {
    this.culexL1_2PositiveCount = culexL1_2PositiveCount;
    this.culexL1_2NegativeCount = culexL1_2NegativeCount;
    this.culexL3_4PositiveCount = culexL3_4PositiveCount;
    this.culexL3_4NegativeCount = culexL3_4NegativeCount;
    this.culexPositiveCount = culexPositiveCount;
    this.culexNegativeCount = culexNegativeCount;
    this.anophelesL1_2PositiveCount = anophelesL1_2PositiveCount;
    this.anophelesL1_2NegativeCount = anophelesL1_2NegativeCount;
    this.anophelesL3_4PositiveCount = anophelesL3_4PositiveCount;
    this.anophelesL3_4NegativeCount = anophelesL3_4NegativeCount;
    this.anophelesPositiveCount = anophelesPositiveCount;
    this.anophelesNegativeCount = anophelesNegativeCount;
    this.pupaePositiveCount = pupaePositiveCount;
    this.pupaeNegativeCount = pupaeNegativeCount;
    this.positiveCount = positiveCount;
    this.negativeCount = negativeCount;
  }
}

export class SurveyedArea {
  readonly area: number;
  readonly percentage: number;

  constructor(
    area: number,
    percentage: number,
  ) {
    this.area = area;
    this.percentage = percentage;
  }
}

export class Summary {
  readonly assignedOn: number | null;
  readonly fetchedOn: number | null;
  readonly startedOn: number | null;
  readonly completedOn: number | null;
  readonly duration: number | null;
  readonly newWaterSourceCount: number;
  readonly totalWaterSourceCount: number;
  readonly treatsByAction: { action: string, count: number, percentage: number }[] | undefined;
  readonly issuesByType: { type: string, count: number, percentage: number }[];
  readonly treatedWaterSourceCount: number | undefined;
  readonly treatedWaterSourcePercentage: number | undefined;
  readonly issuedWaterSourceCount: number;
  readonly issuedWaterSourcePercentage: number;
  readonly sampledWaterSourceCount: number | undefined;
  readonly sampledWaterSourcePercentage: number | undefined;
  readonly sampleResult: SampleResult | undefined;
  readonly surveyedArea: SurveyedArea | undefined;
  readonly houseStats: { startStatus: number | undefined, completeStatus: number, count: number }[];
  readonly visitedWaterSourcePercentage: number;

  constructor(
    assignedOn: number | null,
    fetchedOn: number | null,
    startedOn: number | null,
    completedOn: number | null,
    duration: number | null,
    newWaterSourceCount: number,
    totalWaterSourceCount: number,
    treatsByAction: { action: string, count: number, percentage: number }[] | undefined,
    issuesByType: { type: string, count: number, percentage: number }[],
    treatedWaterSourceCount: number | undefined,
    treatedWaterSourcePercentage: number | undefined,
    issuedWaterSourceCount: number,
    issuedWaterSourcePercentage: number,
    sampledWaterSourceCount: number | undefined,
    sampledWaterSourcePercentage: number | undefined,
    sampleResult: SampleResult | undefined,
    surveyedArea: SurveyedArea | undefined,
    houseStats: { startStatus: number | undefined, completeStatus: number, count: number }[],
    visitedWaterSourcePercentage: number,
  ) {
    this.assignedOn = assignedOn;
    this.fetchedOn = fetchedOn;
    this.startedOn = startedOn;
    this.completedOn = completedOn;
    this.duration = duration;
    this.newWaterSourceCount = newWaterSourceCount;
    this.totalWaterSourceCount = totalWaterSourceCount;
    this.treatsByAction = treatsByAction;
    this.issuesByType = issuesByType;
    this.treatedWaterSourceCount = treatedWaterSourceCount;
    this.treatedWaterSourcePercentage = treatedWaterSourcePercentage;
    this.issuedWaterSourceCount = issuedWaterSourceCount;
    this.issuedWaterSourcePercentage = issuedWaterSourcePercentage;
    this.sampledWaterSourceCount = sampledWaterSourceCount;
    this.sampledWaterSourcePercentage = sampledWaterSourcePercentage;
    this.sampleResult = sampleResult;
    this.surveyedArea = surveyedArea;
    this.houseStats = houseStats;
    this.visitedWaterSourcePercentage = visitedWaterSourcePercentage;
  }
}

export class Task {
  readonly id: number;
  readonly villageId: number;
  status: TaskStatus;
  readonly workType: WorkType;
  readonly modifiedOn: number;
  readonly assignments: Assignment[] | undefined;
  readonly summary: Summary | undefined;
  isLastTask: boolean | undefined;
  expirationLevel: number | undefined;
  targeting: boolean;

  constructor(
    id: number,
    villageId: number,
    status: TaskStatus,
    workType: WorkType,
    modifiedOn: number,
    assignments: Assignment[] | undefined,
    summary: Summary | undefined,
    targeting: boolean,
    isLastTask?: boolean | undefined,
    expirationLevel?: number | undefined,
  ) {
    this.id = id;
    this.villageId = villageId;
    this.status = status;
    this.workType = workType;
    this.modifiedOn = modifiedOn;
    this.assignments = assignments;
    this.summary = summary;
    this.targeting = targeting;
    this.isLastTask = isLastTask;
    this.expirationLevel = expirationLevel;
  }
}

@Injectable({
  providedIn: 'root'
})
export class TaskAdapter implements Adapter<Task> {
  adapt(item: any): Task {
    const status = item.status === TaskStatus.CANCELLED || item.assignments?.length > 0
      ? item.status
      : TaskStatus.UNASSIGNED;

    return new Task(
      item.id,
      item.villageId,
      status,
      item.workType,
      item.modifiedOn,
      item.assignments?.map((item: any) => this.adaptAssignment(item)),
      item.summary ? this.adaptSummary(item.summary) : undefined,
      item.targeting
    );
  }

  adaptAssignment(item: any): Assignment {
    return new Assignment(
      item.id,
      item.userId,
      item.order,
      item.status,
      item.modifiedOn,
      item.assignedOn,
    );
  }

  adaptSummary(item: any): Summary {
    return new Summary(
      item.assignedOn ? item.assignedOn : null,
      item.fetchedOn ? item.fetchedOn : null,
      item.startedOn ? item.startedOn : null,
      item.completedOn ? item.completedOn : null,
      item.duration ? item.duration : null,
      item.newWaterSourceCount,
      item.totalWaterSourceCount,
      item.treatsByAction,
      item.issuesByType,
      item.treatedWaterSourceCount,
      item.treatedWaterSourcePercentage,
      item.issuedWaterSourceCount,
      item.issuedWaterSourcePercentage,
      item.sampledWaterSourceCount,
      item.sampledWaterSourcePercentage,
      item.sampleResult ? this.adaptSampleResult(item.sampleResult) : undefined,
      item.surveyedArea ? this.adaptSurveyedArea(item.surveyedArea) : undefined,
      item.houseStats ? item.houseStats.map((houseStat: any) => {
        return {
          startStatus: houseStat.startStatus,
          completeStatus: houseStat.completeStatus,
          count: houseStat.count
        }
      }) : {
        new: {
          visited: 0,
          notAtHome: 0,
          rejected: 0,
        }
      },
      item.visitedWaterSourcePercentage,
    );
  }

  adaptSampleResult(item: any): SampleResult {
    return new SampleResult(
      item.culexL1_2PositiveCount,
      item.culexL1_2NegativeCount,
      item.culexL3_4PositiveCount,
      item.culexL3_4NegativeCount,
      item.culexPositiveCount,
      item.culexNegativeCount,
      item.anophelesL1_2PositiveCount,
      item.anophelesL1_2NegativeCount,
      item.anophelesL3_4PositiveCount,
      item.anophelesL3_4NegativeCount,
      item.anophelesPositiveCount,
      item.anophelesNegativeCount,
      item.pupaePositiveCount,
      item.pupaeNegativeCount,
      item.positiveCount,
      item.negativeCount,
    );
  }

  adaptSurveyedArea(item: any): SurveyedArea {
    return new SurveyedArea(
      item.area,
      item.percentage,
    );
  }
}
