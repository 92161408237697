import {toZonedTime} from "date-fns-tz";

export const toNullableZonedTime = (date?: Date | string | number, timeZone?: string): Date | undefined => {
  if (!date) {
    return undefined;
  }

  const zone = timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;

  try {
    return toZonedTime(date, zone);
  } catch {
    return undefined;
  }
};
